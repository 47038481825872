import { Controller } from "stimulus"

export default class extends Controller {
	
  static targets = [ "days", "dates", "schedule_type" ]

  connect() {
	this.update_schedule_type();
	  this.disable_inputs_scrool();
  }
  
  update_schedule_type(event) {
	
	// event.preventDefault()
	
	let input_value = this.schedule_typeTarget.value
	
	if (input_value == "fixed_dates") {
		this.datesTarget.classList.remove("hidden");
		this.daysTarget.classList.add("hidden");
	} else {
		this.daysTarget.classList.remove("hidden");
		this.datesTarget.classList.add("hidden");
	}
	
  }


	disable_inputs_scrool(e) {
		var inputs = document.querySelectorAll('input[type="number"]');
		inputs.forEach(function (element) {
			element.parentElement.addEventListener("wheel", (e) => {
				if (e.target.nodeName == "INPUT")
					e.preventDefault();
			})
		});

	}
  
}