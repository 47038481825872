import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
      return ['id', 'stripe_id'];
  }

  toggleAddon(event){
    var plan_id = event.target.getAttribute('data-value');

    if (event.target.checked){
    document.getElementById("subscribe_with_addon_" + plan_id).classList.remove("not-visible");
    document.getElementById("subscribe_without_addon_" + plan_id).classList.add("not-visible");
  }
    else{
      document.getElementById("subscribe_with_addon_" + plan_id).classList.add("not-visible");
      document.getElementById("subscribe_without_addon_" + plan_id).classList.remove("not-visible");
    }



  }


}