import { Controller } from 'stimulus'
import mapboxgl from 'mapbox-gl'
export default class extends Controller {
  connect() {
    this.map = null
    this.initMapbox()
  }

  initMapbox() {
    const mapElement = document.getElementById('map')
    const markers = JSON.parse(mapElement.dataset.markers)

    if (mapElement) {
      
      mapboxgl.accessToken = mapElement.dataset.mapboxApiKey
      
      this.map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/light-v11',
        // style: 'mapbox://styles/mapbox/streets-v11',
        zoom: 7,
        center: [-7.65, 53.66]
		//center: [-122.420019, 37.780091]
      })
	  
      markers.forEach((marker) => {
        this.addMarker(marker)
      })
	  
	  
	  // Add geolocate control to the map.
	  // this.map.addControl(
	  // 		  new mapboxgl.GeolocateControl({
	  // 			  positionOptions: {
	  // 			  	enableHighAccuracy: true
	  // 			  },
	  // 			  trackUserLocation: true
	  // 		  })
	  // );
	  
	  
    }
  }

  addMarker(marker) {
    // new mapboxgl.Marker().setLngLat([marker.lng, marker.lat]).addTo(this.map)
	
	// https://docs.mapbox.com/mapbox-gl-js/api/markers/#popup-example
	
	var popup_html = "<h4 class='h4'>"+ marker.name +"</h4>"
	popup_html += "<p>"+ marker.address +"</p>"
	
	if (marker.url){
		popup_html += "<a href='"+ marker.url +"' target='_blank'>Go to website</a>"
	}
	
	new mapboxgl.Marker().setLngLat([marker.lng, marker.lat]).setPopup(new mapboxgl.Popup().setHTML(popup_html)).addTo(this.map)
  }
}