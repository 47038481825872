import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
      return ['name', 'printer_id'];
  }

  test(event){
    alert("Hello! I am an alert box!!");

  }

  printerOnChange(event) {

      var paper_sizes_dd = document.getElementById('account_printer_paper_size')
      document.getElementById('paper_size_row').classList.remove("hidden");
      var printers_data = document.getElementById('printers-data').innerHTML
      var printers = JSON.parse(printers_data);

    if (event.target.value != "") {
      var papers = printers.find(x => x.id == event.target.value).capabilities.papers
          paper_sizes_dd.options.length = 0;

          var option = document.createElement('option');
          option.value = null;
          option.text = "Choose Paper Size...";
          paper_sizes_dd.options.add(option);

          for (const paper in papers) {
              var opt = document.createElement('option');
              opt.value = paper;
              opt.text =  papers[paper][0] == null ? paper + " - (size not specified)" : paper + " - (" +   Math.floor(papers[paper][0] /10) + "mm x " + Math.floor(papers[paper][1] /10) + "mm)";
              paper_sizes_dd.options.add(opt);
          }
      } else {

        document.getElementById('paper_size_row').classList.add("hidden");
        paper_sizes_dd.options.length = 0;

      }

  }

  paperOnChange(event){
    var selected_printer_id = document.getElementById('account_printer_id').value

    var printers = JSON.parse(document.getElementById('printers-data').innerHTML);
    var papers = printers.find(x => x.id == selected_printer_id).capabilities.papers

    var selected_paper = papers[event.target.value]

    document.getElementById('account_paper_width').value = Math.floor(selected_paper[0] /10)
    document.getElementById('account_paper_height').value = Math.floor(selected_paper[1] /10)

  }
  customSizeOnChange(event){
    if (event.target.checked){
      document.getElementById('paper_height_row').classList.remove("hidden");
      document.getElementById('paper_width_row').classList.remove("hidden");
    }else{
      document.getElementById('paper_height_row').classList.add("hidden");
      document.getElementById('paper_width_row').classList.add("hidden");
    }

  }

}