import { Controller } from "stimulus"

export default class extends Controller {
	
  static targets = [ "coupon_type", "discount_percentage", "discount_amount", "automatic_coupon", "title", "code", "buy_type", "buy_x_get_y", "buy_qty", "buy_amount" ]

  connect() {
	this.update_coupon()
  }
  
  update_coupon(event) {
	
	// event.preventDefault()
	
	let coupon_type_value = this.coupon_typeTarget.value
	
	// alert(coupon_type_value)
	
	// Coupon Type
	if (coupon_type_value == "buy_x_get_y") {
		this.discount_percentageTarget.classList.add("hidden");
		this.discount_amountTarget.classList.add("hidden");
		this.buy_x_get_yTarget.classList.remove("hidden");
	} else if (coupon_type_value == "free_delivery") {
		this.discount_percentageTarget.classList.add("hidden");
		this.discount_amountTarget.classList.add("hidden");
		this.buy_x_get_yTarget.classList.add("hidden");
	} else if (coupon_type_value == "discount_amount") {
		this.discount_percentageTarget.classList.add("hidden");
		this.discount_amountTarget.classList.remove("hidden");
		this.buy_x_get_yTarget.classList.add("hidden");
	} else if (coupon_type_value == "discount_percentage") {
		this.discount_percentageTarget.classList.remove("hidden");
		this.discount_amountTarget.classList.add("hidden");
		this.buy_x_get_yTarget.classList.add("hidden");
	} else {
		this.discount_percentageTarget.classList.remove("hidden");
		this.discount_amountTarget.classList.add("hidden");
		this.buy_x_get_yTarget.classList.add("hidden");
	}
	
	
	// Automatic Coupons
	this.automatic_couponTarget.classList.add("hidden")
	this.titleTarget.classList.add("hidden");
	this.codeTarget.classList.remove("hidden");
	
	
	// Buy X Get Y
    this.buy_typeTargets.forEach( target => {
		// target.classList.toggle(this.toggleClass)
		if(target.value == "buy_min_spend" && target.checked){
			//alert(target.value)
			this.buy_amountTarget.classList.remove("hidden");
			this.buy_qtyTarget.classList.add("hidden");
		} else {
			// buy_min_qty
			this.buy_qtyTarget.classList.remove("hidden");
			this.buy_amountTarget.classList.add("hidden");
		}
    })
	
	
  }
  
}