import { Controller } from "stimulus"

export default class extends Controller {
	
  static targets = [ "qty_type", "qty_available" ]

  connect() {
	this.update_qty();
	this.disable_inputs_scrool();
  }

  update_qty(event) {
	
	// event.preventDefault()
	
	let input_value = this.qty_typeTarget.value
	
	if (input_value == "unlimited_quantity") {
		this.qty_availableTarget.classList.add("hidden");
	} else {
		this.qty_availableTarget.classList.remove("hidden");
	}
	
  }

	disable_inputs_scrool(e){
	  var inputs = document.querySelectorAll('input[type="number"]');
	  inputs.forEach(function (element) {
			element.parentElement.addEventListener("wheel", (e)=>{
			if (e.target.nodeName == "INPUT")
				e.preventDefault();
		  })
	  });

  }
  
}