import { Controller } from "stimulus"

export default class extends Controller {
	
  static targets = [ "map", "grid", "toggle_map", "show_map", "hide_map", "listings" ]

  connect() {
	// this.selectFulfillment()
	// this.showMap()
	// this.hideMap()
	console.log("OPAAAAAAAAA")
  }
  
  
  // Discover Businesses
  
  hideMap(event) {
	event.preventDefault()
	
	this.mapTarget.classList.add("hidden")
	//this.gridTarget.classList.add("grid-cols-1")
	//this.gridTarget.classList.remove("grid-cols-1")
	this.gridTarget.classList.remove("md:grid-cols-2")
	
	this.show_mapTarget.classList.remove("hidden")
	this.hide_mapTarget.classList.add("hidden")
	
	this.listingsTarget.classList.add("lg:grid-cols-4")
	this.listingsTarget.classList.remove("lg:grid-cols-2")
  }
  
  showMap(event) {
	event.preventDefault()
	
	this.mapTarget.classList.remove("hidden")
	//this.gridTarget.classList.add("grid-cols-1")
	this.gridTarget.classList.add("md:grid-cols-2")
	//this.gridTarget.classList.remove("grid-cols-1")
	
	this.show_mapTarget.classList.add("hidden")
	this.hide_mapTarget.classList.remove("hidden")
	
	this.listingsTarget.classList.add("lg:grid-cols-2")
	this.listingsTarget.classList.remove("lg:grid-cols-4")	
  }
  
  
  // Discover Food/Products
  
  
  // Discover Experiences
  
  
  // Check Availability of Products and Reservations
  
  test(account) {
	 window.alert("Test")
  }
  
  
}