import { Controller } from "stimulus"

export default class extends Controller {
	
	static targets = ["option", "order_type", "address", "table", "submit", "delivery_fee", "delivery_amount", "discount_amount", "tip_amount", "total", "tax", "order_item", "unit_price", "tax_rate", "qty", "subtotal", "custom_fee_amount" ]

  connect() {
	this.selectFulfillment()
  }
  
  selectFulfillment(event) {
	
	// alert(event);
	// event.preventDefault()
	
    // this.optionTargets.forEach((el, i) => {
    //   el.classList.toggle("active", event.target == el )
    // })
	
    // this.order_typeTarget.value = event.target.innerText
	
	if (this.order_typeTarget.value == "table_service") {
		this.addressTarget.classList.add("hidden");
		this.tableTarget.classList.remove("hidden");
		this.delivery_feeTarget.classList.add("hidden");
	} else if (this.order_typeTarget.value == "delivery") {
		this.tableTarget.classList.add("hidden");
		this.addressTarget.classList.remove("hidden");
		this.delivery_feeTarget.classList.remove("hidden");
	} else {
		this.addressTarget.classList.add("hidden");
		this.tableTarget.classList.add("hidden");
		this.delivery_feeTarget.classList.add("hidden");
	}
	
	this.calculateTotal()
	
    // this.submitTarget.disabled = false
  }
  
  calculateTotal(event) {
	console.log("ACCAA")
	let tax = 0
	let tax_by_product = 0
	let subtotal = 0

	this.order_itemTargets.forEach((el, i) => {
		let price = Number(el.querySelector('[data-target="pos.unit_price"]').value) * Number(el.querySelector('[data-target="pos.qty"]').value);
		let item_tax = Number(el.querySelector('[data-target="pos.unit_price"]').value) * ((Number(el.querySelector('[data-target="pos.tax_rate"]').value))*0.01) * Number(el.querySelector('[data-target="pos.qty"]').value)
		
		if(el.getAttribute('data-price-including-tax') == "false")
			tax_by_product += item_tax

			tax += Number(item_tax);

			subtotal += Number(price);


	  


	  
	  if (this.order_typeTarget.value == "delivery") {
		  this.totalTarget.innerText = (Number(subtotal) + Number(this.delivery_amountTarget.value) + Number(this.tip_amountTarget.value) - Number(this.discount_amountTarget.value) + (tax_by_product > 0 ? tax_by_product : 0) + Number(this.custom_fee_amountTarget.value)).toFixed(2)
	  }else{
		  this.totalTarget.innerText = (Number(subtotal) + Number(this.tip_amountTarget.value) - Number(this.discount_amountTarget.value) + (tax_by_product > 0 ? tax_by_product : 0) + Number(this.custom_fee_amountTarget.value) ).toFixed(2)
	  }
	  this.subtotalTarget.innerText = subtotal.toFixed(2)

	  this.taxTarget.innerText = tax_by_product > 0 ? Number(tax_by_product).toFixed(2) : Number(tax).toFixed(2)

	  if(tax_by_product > 0){
	  	document.getElementById("subtotal").classList.remove("hidden");
		document.getElementById("tax-title").innerHTML = "Tax"
		}
	  else{
		document.getElementById("subtotal").classList.add("hidden");
		document.getElementById("tax-title").innerHTML = "Tax incl."
	  }

	  
  });
 }
}
