// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>
// https://github.com/SortableJS/Sortable


import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
	
  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: '.handle', // handle's class
      group: 'shared',
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }

  end(event) {

    if (event.from.dataset.collection_id){
      if (event.from.dataset.collection_id != event.to.dataset.collection_id){
        window.alert("Not allowed to move to another category!")
        window.location.reload();
        return;
      }
    }
      
    let id = event.item.dataset.id
    let data = new FormData()
    data.append("position", event.newIndex + 1)
	
    Rails.ajax({
      url: this.data.get("url").replace(":id", id),
      type: 'PATCH',
      data: data
    })
  }
}